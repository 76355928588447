import React from "react";
import styled from "styled-components";
import { scroller ,Element} from "react-scroll";
import { Button } from "../button";
import { Marginer } from "../marginer";
import { CardBody, CardGroup,Card,Input,CardImg} from "reactstrap";
import license from '../../assets/pictures/license.jpg';
import license2 from '../../assets/pictures/license2.jpg';
import license3 from '../../assets/pictures/license3.jpg';
import ceo from '../../assets/pictures/ceo.jpg';
import son from '../../assets/pictures/son.jpg';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";

const FooterContainer = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
  position: relative;
  @media screen and (max-width: 480px) {
   // align-items:center
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const Para=styled.p`
color:whitesmoke;
font-family:'Open Sans', sans-serif;
text-indent:2em;
font-size:16px;
width:80%
`;
const Scard=styled(Card)`
background:#1f1f1f;
`;
const ScardGrp=styled(CardGroup)`
width:100%;
`;
const StyCard1=styled(Card)`
background:#1f1f1f;
border:none;`;

const StyCard2=styled(Card)`
background:#1f1f1f;
align-items:center;
border:none;
@media screen and (max-width: 480px){
align-items:start;
  
 }`;

const StyCard3=styled(Card)`
background:#1f1f1f;
align-items:flex-end;
border:none;
@media screen and (max-width: 480px){
  align-items:start;}
  `;

const Header2=styled.h2`
color:whitesmoke;
font-family:'Open Sans', sans-serif;
border-bottom:1px solid white;
width:120px;
`;
 const Header1=styled.h1`
 color:whitesmoke;
 font-family:'Open Sans', sans-serif;
 `;
  const Header3=styled.h3`
color:whitesmoke;
font-family:'Open Sans', sans-serif;
`;
 const Header4=styled.h4`
color:whitesmoke;
font-family:'Open Sans', sans-serif;
`;
const StyCardBdy=styled(CardBody)`
border-left:1px solid silver;
@media screen and (max-width: 480px){
  border-left:none;
}`; 
const Styform=styled.form`
display:flex;
flex-direction:column;
width:80%;
padding-top:1em;
`;

const StyledLabel=styled.label`
color:whitesmoke;
float:right;
padding-top:1em;


`;

const Link = styled.a`
  color: #fff;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  font-size: 20px;
  border-bottom:1px solid white;
  font-weight:bold;
  &:not(:last-of-type) {
    margin-right: 11px;
   @media screen and (max-width: 480px) {
    margin-right: 9px;
    }}
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }&:hover {
    color:#00B997;
  }
`;
const RightsReserved = styled.div`
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
`;

const StyledButton=styled(Button)`
margin-top:1.5em;
`;

const StyledInput=styled(Input)`
padding-top:1em;
padding-botttom:6em;
`;

const StyledTextArea=styled(Input)`
margin-top:0;

`;

export function Footer(props) {
  
const { handleSubmit,control,errors} = useForm();

  
const onSubmit = data=>{verify(data.spamfilter)&&send(data).then(alert("Message has been sent"))};

const verify=(data)=>{
  if(data==77){
    return true;
  }
return false;

}


const send=async(formData)=>{
  const{name,email,message}=formData;
  let body={"senders name":name,
             "senders email":email,
             "senders message":message}
  const post=await fetch('https://alhinancontact.herokuapp.com/api/Contact',{
    method:'POST',
    body:JSON.stringify(body)
    }).then(response=>{return response}).catch(error=>{alert("refresh the page and try again")})
       }
  const scrollToTop = () => {
    scroller.scrollTo("topSection", { smooth: true, duration: 1500 });
    
  };

  return (
    <FooterContainer>
      <style>
      {`
      .img-4{
             // border-radius:50%;
              width:12em;
              height:8em;
              margin-top:2.5em;
              margin-right:8em;
              
              
            }
            @media screen and (max-width: 480px){
              .img-4{
               width:100%;
               height:195px;
               margin-top:2.5em;
               margin-right:1em;
              }
            }
            .cardbody-1{
              display:flex;
              flex-direction:row;

            }
            @media screen and (max-width: 480px){
              .cardbody-1{
                display:inherit;
                
              }
            }
            
            `}             

      </style>
      <Marginer  direction="vertical"margin="3em"/>
      <CardGroup>
       <Scard> 
      <CardBody>
        <Header2>Founder</Header2>
        <CardBody className="cardbody-1">
        <figure>
        <CardImg className="img-4" src={ceo}/>
        <figcaption style={{color:"whitesmoke"}}>Addis Ayele</figcaption>
        </figure>
        <figure >
        <CardImg className="img-4" src={son}/>
        <figcaption style={{color:"whitesmoke"}}>Abel Addis</figcaption>
        </figure>
        </CardBody>
        <Para>Respected businessman Addis Ayele Is founder and CEO of AL-Hinan Food Complex.
          Addis Ayele is known for many investment projects but what makes this particular under-
          taking 
          different from others is that it was built with a vision to deliver quality products that are not 
          only profitable but safe and sustainable. </Para> 
        <Para>Addis Ayele is a man truly invested and committed in his mission as he has worked 
        hard to create a suitable structure to bring forth this huge plan into reality. He has closely helped his team of 
        smart and efficient staff work and grow together into what is now considered the back bone of this massive operation.</Para>
        <Para>
        Addis Ayele has amassed a great deal of success and has done his fair share in helping others as he 
        continues to do so. This massive manufacturing project is just at its infancy stage and has yet to accomplish 
        wonders.Led by Addis Ayele and his son Abel Addis alongside a team of educated and trained professionals 
        Al-Hinan produces and provides its intended products seamlessly.</Para>
         </CardBody>
        </Scard>
        <Element name="footer">
        <Scard>
        <StyCardBdy>
        <Header1>Contact Info</Header1>
        <Header3>Telephone</Header3>
        <Header4>Sales:+251-969-424242 / +251-983-683969</Header4>
        <Header4>Management:+251-944-754860 /{<br/>} +251-911-253817/+251-911-226104</Header4>
        <Header4>Address:107.2 Km from Addis Ababa <br/>Nazreth,Awash Melkasa</Header4>
        </StyCardBdy>
        <StyCardBdy>
          <Header3>Contact Us</Header3>
        <Styform onSubmit={handleSubmit(onSubmit)}>
        <StyledLabel>Name</StyledLabel>  
        <Controller  
         as={StyledInput}
         name="name"
         control={control}
         placeholder="Name"
         rules={{required:true}}
         />
          <ErrorMessage errors={errors} name="name" as="p" />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  message="This field is required"
                  render={({ message }) => (
                    <p style={{ color:"#00B997" }}>{message}</p>
                  )}
                />
        <StyledLabel>Your Email</StyledLabel>
        <Controller  
         as={StyledInput}
         name="email"
         control={control}
         placeholder="Email"
         rules={{required:true,pattern:/^\S+@\S+$/i}}
        />
        <ErrorMessage errors={errors} name="email" as="p" />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  message="Please input a valid Email address"
                  render={({ message }) => (
                    <p style={{ color:"#00B997" }}>{message}</p>
                  )}
                />
       <StyledLabel>Message</StyledLabel>         
       <Controller  
         as={StyledTextArea}
         name="message"
         control={control}
         type="textarea"
         placeholder="Message"
         rules={{required:true}} 
        />
        <ErrorMessage errors={errors} name="message" as="p" />
                <ErrorMessage
                  errors={errors}
                  name="message"
                  message="This field is required"
                  render={({ message }) => (
                    <p style={{ color:"#00B997" }}>{message}</p>
                  )}
                />
        <StyledLabel>Input the bigger number  between 7 and 77? </StyledLabel>
        <Controller  
         as={StyledInput}
         name="spamfilter"
         control={control}
         placeholder="Spam Filter"
         rules={{required:true}}
          />
        <ErrorMessage errors={errors} name="spamfilter" as="p" />
                <ErrorMessage
                  errors={errors}
                  name="spamfilter"
                  message="Please input the bigger number"
                  render={({ message }) => (
                    <p style={{ color:"#00B997" }}>{message}</p>
                  )}
                />
          <StyledButton onClick={handleSubmit(onSubmit)} >
           {" "}
          Submit
          </StyledButton>
        </Styform>
        </StyCardBdy>
        </Scard>
        </Element>
        </CardGroup>
        <Marginer direction="vertical" margin="4em"/>
        <ScardGrp>
        <StyCard1>
        <CardBody>
          <Link href={license}>License</Link>
        </CardBody>
        </StyCard1>
        <StyCard2>
        <CardBody>
        <Link href={license2}>Certification</Link>
        </CardBody>
        </StyCard2>
        <StyCard3>
          <CardBody>
          <Link href={license3}>TaxPayer Registration</Link>
        </CardBody>
        </StyCard3>
        </ScardGrp>
        <Marginer direction="vertical" margin="4em"/>
        
      <Button onClick={scrollToTop}>Back to Top</Button>
      
      <Marginer direction="vertical" margin="4em"/>
      <RightsReserved>&copy; 2021 Al-Hinan All rights reserved</RightsReserved>
    </FooterContainer>
  
  );
}



