import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Carousell} from "../components/carousel"
import { SectionTitle } from "../components/sectionTitle";
import AboutImgUrl from "../assets/illustrations/fleet.png";
import Service3Img from "../assets/illustrations/quality.png";
import Backg from "../assets/illustrations/section.jpg";
import { Marginer } from "../components/marginer";
import {Card, CardBody} from 'reactstrap';


const MoreAboutContainer = styled(Element)`
  min-height: 550px;
  display: flex;
  flex-direction: column;
  background-image: url(${Backg});
  position: relative;
  background-repeat:no-repeat;
  background-size:cover;
  align-items: center;
  padding: 0 1em;
  @media screen and (max-width: 480px) {
   height: auto;
  
    
  }

`;

const StyledCarousel=styled(Carousell)`
   width: 60%;
   height: 500px;
 @media screen and (max-width: 480px) {
    width: 370px;
    height: 290px;
    margin-left: 0;
  }
  `;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-direction: column-reverse;
  }
`;

const AboutText = styled.p`
  font-size: 18px;
  color: #2f2f2f;
  font-weight: light;
  font-family: 'Raleway', sans-serif;
  line-height: 1.4;
  text-indent:2em;
 @media screen and (max-width: 480px) {
  font-size:14px;
}`;

const items=[
  { 
    src:AboutImgUrl,
    key:'1'
  },
  {
    src:Service3Img,
    key:'2'
  }
]

export function MoreAboutSection(props) {
  return (
    <MoreAboutContainer>
     <SectionTitle> More About us</SectionTitle>
       <AboutContainer>
        <Card>
        <CardBody>  
        <AboutText>
        Built upon 50,000 square meters AL-Hinan Food Complex is a company mainly focused in producing Macaroni and wheat flour. The company focuses on delivering high quality products across Ethiopia and other parts of the world.  

Located in Nazreth, Awash melkasa Al-Hinan plays a vital role in the life’s of the local people. Not only does it provide job opportunities to hundreds of citizens but it fulfills water and other basic needs to many.</AboutText>
<AboutText>   This company can provide 1200 quintals of high end wheat flour and macaroni per day and is fully equipped with its own transportation vehicles that allow it to deliver satisfaction in various places.

This company is established with a vision of producing safe products and doing its part in providing optimal nutrition to its areas of delivery and as so Al-Hinan uses the best of machineries and management to fulfill its vision.

This company is serious about change and is involved in various environmental pursuits like the green imprint movement that plays a massive role in improving quality of life for countless people. It can be seen by the beautiful green scenery around the premises that AL-Hinan’s concern for the environment and society does not stop outside. .{" "}
          </AboutText>
        <AboutText> Our vision is to bring customers food that are safe, 
      of high quality and provide optimal nutrition and push the standard of quality in the 
      manufacturing world here in Ethiopia.
      our Objective is to produce sustainable products of good quality.
      </AboutText> 
        
        </CardBody>
        </Card>
        </AboutContainer>
        <Marginer direction="vertical" margin="2em"/> 
         <StyledCarousel items={items} />
         <Marginer direction="vertical" margin="2em"/> 
    </MoreAboutContainer>
  );
}
