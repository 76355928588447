import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { OurSerivce } from "../components/ourService";
import { FadeInRtL } from "../components/fadeInSection";
import Service1Img from "../assets/illustrations/machines.png";
import Service2Img from "../assets/illustrations/fleet.png";
import Service3Img from "../assets/illustrations/quality.png";
import Service4Img from "../assets/pictures/flourpacked2.jpg";
import Service5Img from "../assets/pictures/macaroni.jpg";
import Service6Img from "../assets/pictures/dough.jpg";
import Dabelo from "../assets/pictures/dabelo.jpg";
import Alhinan from "../assets/logo/logo_croped.png";
import { Card,CardBody, CardGroup, CardHeader, CardImg, CardImgOverlay} from "reactstrap";


const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom:4em;
  flex-wrap: wrap-reverse;
  background:${({background})=>`${background}`};
  @media screen and (max-width: 480px) {
    justify-content: center;
    margin-bottom:26.5em;
    background:${({background})=>`${background}`};
  }
  
`;



const ServicesContainer = styled(Element)`
  width: 100%;
  min-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background: #ADA996;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 @media screen and (max-width: 480px){
   height:3595px;
 }
 @media screen and (max-width: 425px) and (min-width:375px ){
  height:3750px;
}
@media screen and (max-width: 375px) and (min-width:320px ){
  height:3950px;
}
  `;
const Headers=styled.h1`
font-size: 34px;
font-weight: bold;
color: #000;
font-family: 'Zilla Slab', serif;
 `;
const Details=styled.p`
font-size: 24px;
  color: whitesmoke;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  line-height: 1.4;
  padding-top:2em;
 
 @media screen and (max-width: 480px) {
  font-size:28px;
}`;

const ProductDetails=styled.p`
font-size: 1.2em;
  color: whitesmoke;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  padding-top:0em;
  padding-right:1em;
  
 
 @media screen and (max-width: 480px) {
  font-size:1.2em;
}`;



export function ServicesSection(props) {
 return (
    <ServicesContainer name="servicesSection">
      <style>
        {
          `.custom-img{
          height:366px;
          filter: brightness(37%);
          -webkit-filter: brightness(37%);
          -moz-filter: brightness(37%);
          }
          @media screen and (max-width: 480px){
             .custom-img{
              width: 370px;
              height: 290px;
              margin-left: 0;
              }
            }
            .custom-text{
              padding-top:1.5em;
            }
            @media screen and (max-width:480px){
              .custom-text{
                padding-top:0.65em;
              }
            }
            .card-grp{
              height:380px
            }
            @media screen and (max-width: 480px){
              .card-grp{
                width: 370px;
                height: 290px;
                margin-left: 0;
               }
             }
            .card-bo{
              display:flex;
              flex-direction:row;
              flex-wrap:wrap;
            }
            @media screen and (max-width: 480px){
              .card-bo{
                width: 370px;
                height: auto;
                display:flex;
                justify-content:center;
                flex-direction:row;
                flex-wrap:wrap;
               // margin-left: 0;
               }
             }
             @media screen and (max-width: 1152px) and (min-width: 720px){
              .card-bo{
                width: auto;
                height: 290px;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                margin-left: 0;
                flex-basis: 0;
              
                }
             } 
            
            
            .img-1{
              border-radius:50%;
              width:7em;
              height:7em;
              margin-top:2.5em;
              margin-right:8em;
            }
            @media screen and (max-width: 1152px) and (min-width: 720px){
              .img-1{
               width:75px;
               height:75px;
               margin-top:2.5em;
               margin-right:4em;
              
                }
             } 
            
            
            @media screen and (max-width: 480px){
              .img-1{
               width:10em;
               height:10em;
               margin-top:3em;
               margin-right:1em;
              }
            }
          
          

            .img-2{
            border-radius:50%;
            width:7em;
            height:7em;
            margin-top:2.5em;
            }
            @media screen and (max-width: 480px){
              .img-2{
               width:10em;
               height:10em;
               margin-top:1em;
              // margin-left:1em;
               
              }
            }

            @media screen and (max-width: 1152px) and (min-width: 720px){
              .img-2{
              // display:none;
               width:75px;
               height:75px;
               //margin-top:2.5em;
               //margin-right:1em;
                }
             } 
            .small-detail{
              font-size: 20px;
              color: whitesmoke;
              font-weight: 700;
              font-family: 'Raleway', sans-serif;
              margin-bottom:25em;
             }

            
            .card-overlay{
              height:366px;
            }

            .card-overlay2{
              height:366px;
            }
            @media screen and (max-width:480px){
              .card-overlay2{
                height:670px;
              }
            }
            .alhinan{
              padding-top:1em;
              //padding-left:7.5em;
            }
            @media screen and (max-width:480px){
              .alhinan{
                padding-top:.9em;
                //padding-left:0;
                //float:right;
                //font-size:1.5em;
              }
            }
           
           
         .dabelo{
          padding-top:1em;
             
          }
           @media screen and (max-width:480px){
            .dabelo{
              padding-top:.9em;
              //float:right;
             // display:none;
            }
          }
          
          .dabelom{
           display:none;
           font-size:1.5em;

          }
         @media screen and (max-width:480px){
            .dabelom{
              padding-top:.9em;
              float:right;
              display:inherit;
            }
          }
          
          .capacity{
             }
            @media screen and (max-width:480px){
              .capacity{
                margin-top:2.5em;
              }
            } 

            .other{
              margin-top:0;
              padding-top:0;
              border-bottom:solid whitesmoke 1px;
              width:7.5em;
            }
            .custom-img1{
              height:366px;
              filter: brightness(37%);
              -webkit-filter: brightness(37%);
              -moz-filter: brightness(37%);
              }
              @media screen and (max-width: 480px){
                 .custom-img1{
                  width: 370px;
                  height: 680px;
                  margin-left: 0;
                  }
                }
                
                @media screen and (max-width: 480px){
                  .small-title{
                   font-size:1.1em
                   }
                 }
                 
                 @media screen and (max-width: 1152px) and (min-width: 720px){
                  .small-title{
                   font-size:1.1em;
                   margin-top:0;
                   padding-top:0;
                   width:7.5em;
                   
                  }
                 } 
                 .span2{
                   display:-webkit-inline-flex;
                   }
                 
                 
                 @media screen and (max-width: 480px){
                 .span2{
                   display:unset;
                   }
                  }
                 
                 @media screen and (max-width: 1152px) and (min-width: 720px){
                  .span{
                   width:50px;
              
                   
                  }
                 } 



              @media screen and (max-width: 480px){
                .br{
                  display:none;
                }
              }
            
          
          `
        }
      </style>
     <FadeInRtL>  
      <OurSerivce
        title=" Our Products"
        description=" Al-Hinan has been committed to using high end machineries 
                to ensure that we deliver the best of everything to our humble 
                customers from our latest wheat-flour producer to our Macaroni
                machine which is equipped with various structures and molds that 
                is tailored to suit every customer and organization’s needs."
        imgUrl={Service1Img}
        background="whitesmoke"
      />
      </FadeInRtL>
      <FadeInRtL>
      <OurSerivce
        title="Delivery"
        description=" When we say delivery we mean high end transportation, 
        through our fleet of trucks that are equipped with GPS tracking
        and state of the art monitoring and managing system, we offer 
        a very comprehensive delivery service."
        imgUrl={Service2Img}
        isReversed
        background="#EEEEFF"
      />
      </FadeInRtL>
      <FadeInRtL>
      <OurSerivce
        title="Quality is our priority"
        description="  We offer quality in all areas, starting from procuring first grade
        Wheat, careful inspection and the use of our latest machineries that require 
        no hand contact our company does its best in insuring quality and safety."
        imgUrl={Service3Img}
        background="#e5e4e2"
      />
      </FadeInRtL>
       <CardContainer>
       <CardGroup className="card-grp">
       <Card>
         <CardHeader>
           <Headers>Products</Headers>
         </CardHeader>
         <CardImg className="custom-img1" src={Service5Img}/>
         <CardImgOverlay className="card-overlay2">
         <CardBody className="card-bo">
            <span className="span2">
           <div >
           <CardImg src={Dabelo} className="img-1" />
           <ProductDetails className="dabelo"> Dabelo <br className="br"/> Macaroni</ProductDetails>
           </div>
          <div >        
            <CardImg src={Alhinan} className="img-2"/>
            <ProductDetails className="alhinan">Al-hinan <br className="br"/> wheat flour</ProductDetails>
          </div>
         </span>
        <span className="span">
        <CardBody>
          <Details className="other">other products</Details>
          <ul >
          <ProductDetails className="small-title">-Frushka</ProductDetails>
          <ProductDetails className="small-title">-Freshkelo</ProductDetails>
          </ul>
          </CardBody>
 
         </span>
         
           </CardBody>
          </CardImgOverlay>
       </Card>
       <Card className="capacity">
         <CardHeader>
           <Headers>Capacity</Headers>
         </CardHeader>
         <CardImg className="custom-img" src={Service4Img}/>
         <CardImgOverlay className="card-overlay">
         <CardBody>
           <ul style={{paddingTop:"1.5em"}}>
           <Details>1200 quintals of quality wheat flour</Details>
           <Details>500 quintals of quality macaroni</Details>
           </ul>
           </CardBody>
           </CardImgOverlay>
       </Card>
       <Card>
         <CardHeader>
           <Headers>Size</Headers>
         </CardHeader>
         <CardImg  className="custom-img" src={Service6Img}/>
         <CardImgOverlay className="card-overlay">
         <CardBody >
           <ul style={{paddingTop:"1.5em"}}>
           <Details className="custom-text">5Kg</Details>
           <Details className="custom-text">10Kg</Details>
           <Details className="custom-text">25Kg</Details>
           <Details className="custom-text">50Kg</Details>
           </ul>
           </CardBody>
        </CardImgOverlay>
       </Card>
       </CardGroup>
       </CardContainer>
       
      
    </ServicesContainer>
  );
}
