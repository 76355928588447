const { default: styled } = require("styled-components");

export const SectionTitle = styled.h1`
  font-size: 34px;
  background:#5e5e5b;
  border-radius: 25px;
  border: 2px solid #5e5e5b;
  font-size: 34px;
  font-weight: bold;
  font-family: 'Zilla Slab', serif;
  margin: 5px 0;
  color:whitesmoke;
  
  @media screen and (max-width: 480px) {
    text-align: center;
    padding-top:3vh;
    
    
  }
`;
