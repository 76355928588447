import React from "react";
import { Element, scroller } from "react-scroll";
import styled from "styled-components";

import BackgroundImg from "../assets/pictures/indexbg.jpg";
import { DownArrow } from "../components/downArrow";
import { Logo } from "../components/logo";
import { Marginer } from "../components/marginer";
import { Navbar } from "../components/navbar";
import {FadeInUtD} from "../components/fadeinUtD"

const TopContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  background-image: url(${BackgroundImg});
  position: relative;
  background-repeat:no-repeat;
  background-size:cover;
  
  
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, 0.89);
  animation: pulse 5s infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
  @keyframes pulse {
    0% {
      opacity: 0.9;
    }
    100% {
      opacity:1;
    }
  }


`;

const MotivationalText = styled.h1`
  font-size: 34px;
  font-weight: 500;
  line-height: 1.4;
  font-family: Brush Script MT, Brush Script Std, cursive;
  color: #fff;
  margin: 0;
  text-align: center;
`;

const DownArrowContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
`;

export function TopSection(props) {
  const scrollToNextSection = () => {
    scroller.scrollTo("servicesSection", { smooth: true, duration: 1500 });
  };

  return (
    <Element name="topSection">
      <TopContainer>
        <BackgroundFilter>
           <Navbar />
          <Marginer direction="vertical" margin="8em" />
          <FadeInUtD>
          <Logo />
          </FadeInUtD>
          <Marginer direction="vertical" margin="4em" />
          <MotivationalText>Your satsfaction is our </MotivationalText>
          <MotivationalText>prime concern !</MotivationalText>
          <Marginer direction="vertical" margin="6em" />
          <DownArrowContainer onClick={scrollToNextSection}>
            <DownArrow />
          </DownArrowContainer>
        </BackgroundFilter>
      </TopContainer>
    </Element>
  );
}
