import React, { useState } from 'react';
import slide6 from "../../assets/pictures/machinery.jpg";
import slide5 from "../../assets/pictures/delivery3.jpg";
import slide4 from"../../assets/pictures/team.jpg";
import slide7 from"../../assets/pictures/green5.jpg";
import slide8 from"../../assets/pictures/green4.jpg";
import slide1 from"../../assets/pictures/green3.jpg";
import slide10 from"../../assets/pictures/green2.jpg";
import slide11 from"../../assets/pictures/green.jpg";
import slide3 from"../../assets/pictures/fuelstation.jpg";
import slide2 from"../../assets/pictures/flouropen.jpg";
import slide9 from"../../assets/pictures/flourpacked.jpg";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    id: 1,
    src:slide1
  },
  {
    id: 2,
    src:slide2
  },
  {
    id: 3,
    src:slide3
  },
  {
    id: 4,
    src:slide4
  },
  {
    id: 5,
    src:slide5
  },
  {
    id: 6,
    src:slide6
  },
  {
    id: 7,
    src:slide7
  },
  {
    id: 8,
    src:slide8
  },
  {
    id: 9,
    src:slide9
  },
  {
    id: 10,
    src:slide10
  },
  {
    id: 11,
    src:slide11
  },
   ];

  const Carousell = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.key}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      > 
         <img className="custom-tag" src={item.src} alt="slide pictures"/>
        <CarouselCaption className="text-light"  captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <div>
      <style>
        {
          `.custom-tag {
            width: 600px;
            height: 500px;
              }
            @media screen and (max-width: 480px){
             .custom-tag{
              width: 370px;
              height: 290px;
              margin-left: 0;
              }
            }
          @media screen and(max-width:480px){
              .carousel{
                margin-left:0;
              }
            }
          
          `
        }
      </style>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className="carousel"
      >
        <CarouselIndicators  items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl className="text-dark" direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl className="text-dark" direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );
}

export{Carousell};






