import React from "react";
import styled from "styled-components";
import { Footer } from "../components/footer";
import { MoreAboutSection } from "./moreAboutSection";
import { ServicesSection } from "./servicesSection";
import { TopSection } from "./topSection";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;
const Margin=styled.span`

@media screen and (max-width: 480px){
diplay:flex;
height:9vh;;}
  
  `;


export function Homepage(props) {
  return (
    <PageContainer>
      <TopSection />
      <ServicesSection />
      <Margin/>
      <MoreAboutSection />
      <Footer />
    </PageContainer>
  );
}
