import React from "react";
import styled from "styled-components/macro";
import { FadeInLtR } from "../fadeinLtR";


const ServiceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isReversed }) => isReversed && "row-reverse"};
  flex-wrap: wrap-reverse;
  background:${({background})=>`${background}`};
  @media screen and (max-width: 480px) {
    justify-content: center;
    margin-bottom:2em;
    background:${({background})=>`${background}`};
  }
  
`;

const ServiceImg = styled.img`
  width: 22em;
  height: 20em;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% ;
  color: white;
  box-shadow: 5px 5px rgba(0,0,0,.15);
  transition: all .4s ease;
  &:hover {  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ;
    box-shadow: 5px 5px rgba(0,0,0,.25);
  }
 @media screen and (max-width: 480px) {
    width: 18em;
    height: 13em;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%;
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  color: white;
  background:#5e5e5b;
  border-radius: 25px;
  border: 2px solid #5e5e5b;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Zilla Slab', serif;
  margin: 5px 0;

  @media screen and (max-width: 480px) {
    margin-top: 1.5em;
  }
`;

const Details = styled.p`
  color: #7a7a7a;
  font-size: 21px;
  text-align: center;
  max-width: 55%;
  font-family: 'Raleway', sans-serif;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export function OurSerivce(props) {
  const { imgUrl, title, description, isReversed,background } = props;
  return (
  <ServiceContainer isReversed={isReversed} background={background}>
      <DescriptionContainer >
        <Title>{title}</Title>
        <Details>{description}</Details>
      </DescriptionContainer>
      <FadeInLtR>       
        <ServiceImg src={imgUrl}/>
        </FadeInLtR>
        </ServiceContainer>
     );
}
